import React from "react";
import pellets from "../static/images/woolpellets.jpg";
export function WoolPellets() {
    return (
        <div className="wool-pellets">
            <div className="left-container">
            <img src={pellets} alt="wool pellets" />;
            </div>
            <div className="right-container">
               <h3>We take the belly wool from our sheep and send it to Wild Valley Farms. There they transfor our wool into pellets to sell to you.</h3>
               <p>
The perfect addition for both outdoor gardens and your indoor plants.
​​Macronutrients: Nitrogen, Phosphorus, Potassium, Calcium, Magnesium, and Sulfur ​​
Micronutrients: Iron, Manganese, Boron, Copper, Zinc, Molybdenum, Sodium</p>
                <p>Wool Pellets are ORGANIC, SUSTAINABLE, ENVIROMENTALLY and CLIMATE FRIENDLY.
They are made from 100% RAW WOOL from U.S.  ranchers.
Wool Pellets are able to HOLD 20% THEIR WEIGHT IN WATER helping to reduce the times you water.  ​
By holding water, they can WICK AWAY EXTRA WATER, protecting your plants from over watering.
Wool pellets expand with water helping to INCREASE POROSITY in the soil for optimal root growth.  Reducing the need for additives like Perlite.
Wool Pellets are NPK 9-0-2 slow-release FERTILIZER, so they help your plants grow all year long. 
Wool Pellets IMPROVE YOUR SOIL creating an environment for healthy plants and flavorful produce. </p>
            </div>
         </div>
    );
}
export default WoolPellets;
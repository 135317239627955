import React from "react";

import { WinnerCard } from "./WinnerCards";

import ChampionFleece from "../../static/images/champion_fleece.jpeg";
import Ole from "../../static/images/Ole.jpg";


export function WinnerCircle() {
    return (
        <>
        <div className="Winner-top">
            <h1>Our Success</h1>
            <h2> Throughout the years Tanya and Alan had numerous amounts of success showing the sheep 
                and the wool. Some highlights that will hold dear to our families hearts are:
            </h2>
            <h2>
                ~ Utah State Fair Supreme Champion Ram<br></br>
                ~ National Stock Show Supreme Champion Ram<br></br>
                ~ National Stock Show Supreme Champion Ewe<br></br>
                ~ National Stock Show Champion Fleece<br></br>
            </h2>
        </div>
        <div className="winner-cards-wrapper">
            <WinnerCard imgSrc={ChampionFleece}
            imgAlt="National Stock Show"
            title="National Stock Show"
            description="Leroy was our very first natural colored Columbia to add to our flock. 
            He has had many sons, daughters, and grandkids win fleece competitions. He finally 
            earned his stripes by winning the National Stock Show Natural Colored Fleece Show." 
            // quantity="Quantity: 5 packages"
            // price="Price: $10 per pound"
            />
            <WinnerCard imgSrc={Ole}
            imgAlt="National Stock Show"
            title="2019 National Stock Show"
            description="Ole is one of our raised Columbia Ramboulet Cross rams. We kept him for our 
            breeding program to get more paints. He won the supreme champion natural colored division."
            // quantity="Quantity: 15 packages"
            // price="Price: $13 per pound"
            />
            {/* <WinnerCard imgSrc={Roast}
            imgAlt="shoulder roast"
            title="Shoulder Roast"
            description="You can choose to have the roast turned into ground or shoulder chops." */}
        
            {/* /> */}
            {/* <WinnerCard imgSrc={Stew}
            imgAlt="stew Winner"
            title="Stew Winner"
            description="This also works great for shis kabobs"
          
            />
            <WinnerCard imgSrc={AmericanSausage}
            imgAlt="American Sausage"
            title="American Sausage"
            description="Makes great breakfast burritos. There is no spiceness to this sausage. Sausage is ground. Comes in 1 pound packages."
       
            />
             <WinnerCard imgSrc={ItalianSausage}
            imgAlt="italian sausage"
            title="Italian Sausage"
            description="Comes in hot or mild. The hot isn't too spicy but does have a small kick.Sausage is ground. Comes in 1 pound packages."
          
            />
            <WinnerCard imgSrc={LambChops} 
            imgAlt="lamb chops"
            title="Lamb Chops"
            description="Comes in loin chops or shoulder chops. Shoulder chops are much bigger."
 
            />
            <WinnerCard imgSrc={Shanks}
            imgAlt="shanks"
            title="Shanks"
            description="Quantity: 5 packages"
           
            /> */}
        </div>
        {/* <div className="footer">
            <center><h1>Interested in buying a lamb or individual packages contact Tanya or Rex Hamner. 
                <br></br><br></br>Email: hamnerlivestock@yahoo.com<br></br>
                Tanya Hamner: 307-679-8749<br></br>Rex Hamner: 307-679-5158</h1></center>
        </div> */}

        </>
    );
}
export default WinnerCircle;
import React from "react";

// import Meat from "../../static/images/ground.jpeg";

export const BreedingStockCards = ({
  bstockimgSrc,
  // imgAlt,
  bstockcardTitle,
  bstockcarddescription,
  bstockbuttonText,
  bstockcardLink,
}) => {
  return (
    <div className="bstockcard-container">
      {bstockimgSrc &&  (<img src={bstockimgSrc}  className="bstockcard-img" />)}
       {bstockcardTitle && 
       <h1 className="bstockcard-title">{bstockcardTitle}</h1> }
       {bstockcarddescription && 
       <p className="bstockcard-description">
        {bstockcarddescription}</p>}

        {bstockbuttonText && bstockcardLink && (
       <a href = {bstockcardLink} className="bstockcard-btn">
        {bstockbuttonText}
       </a>
       )}
    </div>
  )
};
import React from "react";



export const WinnerCard = ( {
  imgSrc,
  imgAlt,
  title,
  description,
  quantity,
  price
}) => {
    return (
      <div className="winner-card-container">
        {imgSrc && imgAlt && (
        <img src={imgSrc} alt={imgAlt} className="winner-card-img"/>
      )}
        {title && <h1 className="winner-card-title">{title}</h1>}
        {description && <p className="winner-card-description">{description}</p>}
        {quantity && <p className="winner-card-quantity">{quantity}</p>}
        {price && <p className="winner-card-price">{price}</p>}
      </div>
    );
}
  


import React from "react";

// import Meat from "../../static/images/ground.jpeg";

export const WoolCards = ({
  woolimgSrc,
  // imgAlt,
  woolcardTitle,
  woolcarddescription,
  woolbuttonText,
  woolcardLink,
}) => {
  return (
    <div className="woolcard-container">
      {woolimgSrc &&  (<img src={woolimgSrc}  className="woolcard-img" />)}
       {woolcardTitle && 
       <h1 className="woolcard-title">{woolcardTitle}</h1> }
       {woolcarddescription && 
       <p className="woolcard-description">
        {woolcarddescription}</p>}

        {woolbuttonText && woolcardLink && (
       <a href = {woolcardLink} className="woolcard-btn">
        {woolbuttonText}
       </a>
       )}
    </div>
  )
};
import React  from "react";
// import { v4 as uuid } from 'uuid';

// import { HomeCard } from "./HomeCards";



import { BreedingStockCards } from "./BreedingStockCards";

import Pearl from "../../static/images/Pearl.jpg";
import Silverado from "../../static/images/Silverado.jpg";
import Roath from "../../static/images/roath.jpg";
import Bandit from "../../static/images/Bandit.jpg";
import Dutch from "../../static/images/Dutch.jpg";
import lambs from "../../static/images/Hamner Livestock-3299.jpg";
import lambs2 from "../../static/images/Hamner Livestock-3281.jpg";
import lambs3 from "../../static/images/Hamner Livestock-3284.jpg";
import lambs4 from "../../static/images/Hamner Livestock-3294.jpg";
import lambs5 from "../../static/images/NFC_9095.jpg";
import lambs6 from "../../static/images/NFC_9051.jpg";
import sheep from "../../static/images/Breeding sheep.jpg";
import sheep1 from "../../static/images/Banner Image.jpg";
import sheep2 from "../../static/images/Hamner Livestock-3233.jpg";
// import sheep3 from "../../static/images/Hamner Livestock-3234.jpg";
import sheep4 from "../../static/images/Hamner Livestock-3237.jpg";
// import sheep5 from "../../static/images/Hamner Livestock-3240.jpg";
// import sheep6 from "../../static/images/Hamner Livestock-3246.jpg";
import sheep7 from "../../static/images/Hamner Livestock-3258.jpg";
import sheep8 from "../../static/images/Hamner Livestock-3268.jpg";
// import sheep9 from "../../static/images/Hamner Livestock-3269.jpg";
// import sheep10 from "../../static/images/Hamner Livestock-3274.jpg";
import sheep11 from "../../static/images/Hamner Livestock-3275.jpg";
import sheep12 from "../../static/images/Hamner Livestock-3285.jpg";
import sheep13 from "../../static/images/Hamner Livestock-3288.jpg";
import sheep14 from "../../static/images/Hamner Livestock-3292.jpg";
import sheep15 from "../../static/images/NFC_9004.jpg";
import sheep16 from "../../static/images/NFC_9006.jpg";
import sheep17 from "../../static/images/NFC_9048.jpg";
import sheep18 from "../../static/images/banner 1.jpg";
import sheep19 from "../../static/images/banner 2.jpg";
function BreedingStock() {
    // const [reviews, setReviews] = useState([]);
    // const [form, setForm] = useState({product: "", review: "", customer: "", id: uuid() });
    // const [editing, setEditing] = useState(false);
    // const [number, setNumber] = useState(0);
    
    return (
        <>
        
            <div className="bstock-main-wrapper">
                <div className="bstock--top">
                    <h1>Our Current Rams</h1>
                    <p>We look at pedigree's and where the buck has lived to decide who we are going to purchase from. Our first priority is 
                        keeping the traditional Columbia characteristics in the breed. We look for Columbia rams that are not leathered neck,
                        do not have horns, and non wrinkle noses. We also look for rams that do not have wooly faces. The second priority in
                        our rams is the fleece. We want rams that are going to produce the best fleece a Columbia ram can produce for our
                        handspinners.
                    </p>
                </div>

                <div className="bstock-cardWrapper">
                    <BreedingStockCards
                        bstockimgSrc={Bandit}
                        // imgAlt="Card Image"
                        bstockcardTitle="Bandit"
                        bstockcarddescription="Bandit is one of our rams that was born from our flock. We usually do not keep
                        our rams for breeding but Bandit is such a pretty ram that we decided to keep him and purchase ewes for him
                        to breed. He has a natural paint throughout his body that consists of grey, charcoal, white, and black." 
                    />
                  
                    <BreedingStockCards
                        bstockimgSrc={Roath}
                        // imgAlt="Card Image"
                        bstockcardTitle="Roathe"
                        bstockcarddescription="We purchased Roath from the Roath Columbia's. He is our main breeding buck." 
                    />
                      <BreedingStockCards
                        bstockimgSrc={Dutch}
                        // imgAlt="Card Image"
                        bstockcardTitle="Dutch"
                        bstockcarddescription="Dutch is another ram that we kept from our flock. Tanya has been trying to get paint ewes
                        and keeps getting paint bucks. She keeps the paints for the handspinners. Dutch will have his own set of ewes that we
                        purchased to keep the genetic tree branching." 
                    />
                </div>
                <div className="bstock--middle">
                    <h1>Our Ewes</h1>
                    <p>We try to keep at least 6 ewes each lambing season to keep in the flock. We have some bloodlines that go clear back
                        to the Robertsons and Platts. It is important to us to keep the original bloodlines from our original flock. Our ewes
                        have non wool faces and amazing wool for a Columbia ewe. 
                    </p>
                </div>
                <div className="bstock-cardWrapper">
                    <BreedingStockCards
                        bstockimgSrc={Pearl}
                        // imgAlt="Card Image"
                        bstockcardTitle="The Queen"
                        bstockcarddescription="When Tanya started the natural colored flock in 2013, she bought a Columbia Rambouillet cross buck named Leroy
                        to breed with a few of our Columbia Ewes. The Queen is one of Leroy's first ewes. She was born in 2014. She has given us many lambs that 
                        have won contests with their fleeces. Her fleece is now a beautiful silver fleece." 
                    />
                  
                    <BreedingStockCards
                        bstockimgSrc={Silverado}
                        // imgAlt="Card Image"
                        bstockcardTitle="Silverado"
                        bstockcarddescription="Silverado is another original ewe from Leroy. Her fleece has won in its class at Estes Wool Show. She was born in 2015." 
                    />
                </div>
                <div className="bstock--middle">
                    <h1>Our Lambs</h1>
                    <p>Most of our lambs we sell as meat to customers when they reach 150 to 200 pounds. Usually they are about 10 months old
                        before going to the butcher. We try to keep about 6 ewe lambs for replacement stock in the ewe flock. We have sold lambs
                        as show stock and replacement stock. 
                    </p>
                </div>
                <div className="bstock-cardWrapper">
                    <BreedingStockCards
                        bstockimgSrc={lambs}
                        // imgAlt="Card Image"
                    />
                    <BreedingStockCards
                        bstockimgSrc={lambs2}
                        // imgAlt="Card Image"
                    />
                    <BreedingStockCards
                        bstockimgSrc={lambs3}
                        // imgAlt="Card Image"
                    />
                    <BreedingStockCards
                        bstockimgSrc={lambs4}
                        // imgAlt="Card Image"
                    />
                    <BreedingStockCards
                        bstockimgSrc={lambs5}
                        // imgAlt="Card Image"
                    />
                    <BreedingStockCards
                        bstockimgSrc={lambs6}
                        // imgAlt="Card Image"
                    />
                     <BreedingStockCards
                        bstockimgSrc={sheep}
                        // imgAlt="Card Image"
                    />
                    <BreedingStockCards
                        bstockimgSrc={sheep1}
                        // imgAlt="Card Image"
                    />
                    <BreedingStockCards
                        bstockimgSrc={sheep2}
                        // imgAlt="Card Image"
                    />
                    {/* <BreedingStockCards
                        bstockimgSrc={sheep3}
                        // imgAlt="Card Image"
                    /> */}
                    <BreedingStockCards
                        bstockimgSrc={sheep4}
                        // imgAlt="Card Image"
                    />
                    {/* <BreedingStockCards
                        bstockimgSrc={sheep5}
                        // imgAlt="Card Image"
                    /> */}
                    {/* <BreedingStockCards
                        bstockimgSrc={sheep6}
                        // imgAlt="Card Image"
                    /> */}
                    <BreedingStockCards
                        bstockimgSrc={sheep7}
                        // imgAlt="Card Image"
                    />
                    <BreedingStockCards
                        bstockimgSrc={sheep8}
                        // imgAlt="Card Image"
                    />
                    {/* <BreedingStockCards
                        bstockimgSrc={sheep9}
                        // imgAlt="Card Image"
                    /> */}
                    {/* <BreedingStockCards
                        bstockimgSrc={sheep10}
                        // imgAlt="Card Image"
                    /> */}
                    <BreedingStockCards
                        bstockimgSrc={sheep11}
                        // imgAlt="Card Image"
                    />
                    <BreedingStockCards
                        bstockimgSrc={sheep12}
                        // imgAlt="Card Image"
                    />
                    <BreedingStockCards
                        bstockimgSrc={sheep13}
                        // imgAlt="Card Image"
                    />
                    <BreedingStockCards
                        bstockimgSrc={sheep14}
                        // imgAlt="Card Image"
                    />
                   
                    <BreedingStockCards
                        bstockimgSrc={sheep16}
                        // imgAlt="Card Image"
                    />
                    <BreedingStockCards
                        bstockimgSrc={sheep17}
                        // imgAlt="Card Image"
                    />
                    <BreedingStockCards
                        bstockimgSrc={sheep18}
                        // imgAlt="Card Image"
                    />
                    <BreedingStockCards
                        bstockimgSrc={sheep19}
                        // imgAlt="Card Image"
                    />
                     <BreedingStockCards
                        bstockimgSrc={sheep15}
                        style ="padding-bottom:2%"
                        // imgAlt="Card Image"
                    />

                </div>
            </div>

        </>
    );
}


export default BreedingStock;
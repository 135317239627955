import React from "react";

// import Meat from "../../static/images/ground.jpeg";

export const HomeCards = ({
  homeimgSrc,
  // imgAlt,
  homecardTitle,
  homecarddescription,
  homebuttonText,
  homecardLink,
}) => {
  return (
    <div className="homecard-container">
      {homeimgSrc &&  (<img src={homeimgSrc}  className="homecard-img" />)}
       {homecardTitle && 
       <h1 className="homecard-title">{homecardTitle}</h1> }
       {homecarddescription && 
       <p className="homecard-description">
        {homecarddescription}</p>}

        {homebuttonText && homecardLink && (
       <a href = {homecardLink} className="homecard-btn">
        {homebuttonText}
       </a>
       )}
    </div>
  )
};
// import { Link } from "react-router-dom"
// import NavLogo from "../static/images/logo2.png";
import {useRef} from "react";
import { Link, useMatch, useResolvedPath} from "react-router-dom"

import { FaBars, FaTimes } from "react-icons/fa";

import NavLogo from "../static/images/logo2.png";

export default function Navbar() {

const navRef = useRef();
const showNavbar = () => {
  navRef.current.classList.toggle("responsive_nav");
}

  return (

      <header>
        <img className="header-logo" src={ NavLogo } />
        <div className="brand-title">Hamner Livestock</div>
        <nav ref={navRef}>
          <a href="/#">Home</a>
          <a href="/aboutus">About Us</a>
          <a href="/winner">Winners Circle</a>
          <a href="/meatforsale">Meat for Sale</a>
          <a href="/wool">Wool</a>
          <a href="/breedingstock">Stock</a>
          {/* <a href="/#">Breeding Stock</a>
          <a href="/#">Wool</a>
          <a href="/#">Wool Pellets</a> */}
         
        
          <button className="nav-btn nav-close-btn" onClick={showNavbar}>
            <FaTimes />
          </button>
        </nav>
        <button className="nav-btn" onClick={showNavbar} >
          <FaBars />
        </button>
      </header>
      // <nav className="navbar">
      //     <img className="header-logo" src={ NavLogo } />
      //     <div className="brand-title">Hamner Livestock</div>
          
          /* <a href ="#" class="toggle-button">
            <span class = "bar"></span>
            <span class = "bar"></span>
            <span class = "bar"></span>
          </a> */
          // <div className="navbar-links">
          // <ul>
          // <CustomLink to="/">Home</CustomLink>
          //       <CustomLink to="/aboutus">About Us</CustomLink>
          //         <CustomLink to="/winner">Winners Circle</CustomLink>
          //         <CustomLink to="/meatforsale">Breeding Stock</CustomLink>
          //         <CustomLink to="/meatforsale">Meat for Sale</CustomLink>
          //         <CustomLink to="/meatforsale">Wool</CustomLink>
          //         <CustomLink to="/meatforsale">Wool Pellets</CustomLink>
               
                /* <CustomLink to="/woolpellets">Wool Pellets</CustomLink> */
      //           </ul>
      //     </div>
      // </nav>
    // <nav className="nav">
        
    //     <Link to="/" className="site-title">Hamner Livestock
    //       <img src={Logo} alt="logo" />
    //     </Link>
    //       <ul>
          
    //         <CustomLink className="navlinks" to="/aboutus">About Us</CustomLink>
    //         <CustomLink className="navlinks" to="/winner">Winners Circle</CustomLink>
    //         <CustomLink className="navlinks" to="/meatforsale">Breeding Stock</CustomLink>
    //         <CustomLink className="navlinks" to="/meatforsale">Meat for Sale</CustomLink>
    //         <CustomLink className="navlinks" to="/meatforsale">Wool</CustomLink>
    //         <CustomLink className="navlinks" to="/meatforsale">Wool Pellets</CustomLink>
          
    //       {/* <CustomLink to="/woolpellets">Wool Pellets</CustomLink> */}
    //       </ul>
        
    // </nav>
  )
}

function CustomLink ({ to, children, ...props}) {
  const resolvedPath = useResolvedPath(to)
  const isActive = useMatch({ path: resolvedPath.pathname, end: true})
  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  )
}



import React from "react";

import { MeatCard } from "./PMeatCards";

import Ground from "../../static/images/ground.jpeg";
import Leg from "../../static/images/leglamb copy.jpeg";
import Roast from "../../static/images/roast.jpg";
import Stew from "../../static/images/stewmeat.jpeg";
import AmericanSausage from "../../static/images/sausage.jpeg";
import ItalianSausage  from "../../static/images/sausage.jpeg";
import LambChops from "../../static/images/lambchops.jpg";
import Shanks from "../../static/images/shanks.jpg";

export function MeatForSale() {
    return (
        <>
        <div className="meat-top">
            <h1>Serve Our Meat</h1>
            <p>What makes our meat so tasty is the dedication and research put into how much feed and quality of feed
                makes the meat. The sheep are fed Walton's alfalfa pellets mixed with 4-H grain (that is made up of oats, barley
                corn, molasses, canola, urea, and a protein premix) with free choice grass hay. <br></br><br></br>
                The lambs are butchered between 150 - 200 pounds. <br></br><br></br>Our lamb meat is butchered at Theurer’s 
                Quality Meats and Hendersons and is USDA Inspected. <br></br><br></br>To order Specialty Cuts such as crowns, 
                lollipops, french cut legs, or racks, you need to purchase
                the lamb as a whole. This is $450 and you get all the meat from that lamb.<br></br><br></br>
                Buy a whole lamb for $400. If you purchase a whole lamb you can have it custom cut, you pick your thickness 
                and how many chops per package. You will receive between 55-85 pounds of meat depending on how much your lamb weighs. <br></br>

            </p>
        </div>
        <div className="meat-cards-wrapper">
            <MeatCard imgSrc={Ground}
            imgAlt="ground meat"
            title="Ground Meat"
            description="Comes in 1 pound packages." 
            quantity="Quantity: 5 packages"
            price="Price: $7 per pound"
            />
            <MeatCard imgSrc={Leg}
            imgAlt="leg of lamb"
            title="Leg of Lamb"
            description="These are cut in halves, if you are interested in whole legs let me know and I will get a lamb butchered 
            that way."
            quantity="Quantity: 15 packages"
            price="Price: $10 per pound"
            />
            <MeatCard imgSrc={Roast}
            imgAlt="shoulder roast"
            title="Shoulder Roast"
            description="You can choose to have the roast turned into ground or shoulder chops."
            quantity="Quantity: 4 packages"
            price="Price: $10 per pound"
            />
            <MeatCard imgSrc={Stew}
            imgAlt="stew meat"
            title="Stew Meat"
            description="This also works great for shis kabobs"
            quantity="Quantity: 15 packages"
            price="Price: $7 per pound"
            />
            <MeatCard imgSrc={AmericanSausage}
            imgAlt="American Sausage"
            title="American Sausage"
            description="Makes great breakfast burritos. There is no spiceness to this sausage. Sausage is ground. 
            Comes in 1 pound packages."
            quantity="Quantity: 15 packages"
            price="Price: $7 per pound"
            />
             <MeatCard imgSrc={ItalianSausage}
            imgAlt="italian sausage"
            title="Italian Sausage"
            description="Comes in hot or mild. The hot isn't too spicy but does have a small kick. Sausage is ground. 
            Comes in 1 pound packages."
            quantity="Quantity: 15 hot packages, 10 mild packages."
            price="Price: $7 per pound"
            />
            <MeatCard imgSrc={LambChops} 
            imgAlt="lamb chops"
            title="Lamb Chops"
            description="Comes in loin chops or shoulder chops. Shoulder chops are much bigger. Comes 4 per package."
            quantity="Quantity: 15 packages"
            price="Price: $10 per pound"
            />
            <MeatCard imgSrc={Shanks}
            imgAlt="shanks"
            title="Shanks"
            // description="Quantity: 5 packages"
            quantity="Quantity: 15 packages"
            price="Price: $10 per pound"
            />
        </div>
        <div className="footer">
            <center><h1>Interested in serving our lamb at your restaurant, or selling it out of your store contact Tanya or Rex Hamner. 
                <br></br><br></br>Email: hamnerlivestock@yahoo.com<br></br>
                Tanya Hamner: 307-679-8749<br></br>Rex Hamner: 307-679-5158</h1></center>
        </div>

        </>
    );
}
export default MeatForSale;
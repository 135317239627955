import React  from "react";
// import { v4 as uuid } from 'uuid';

// import { HomeCard } from "./HomeCards";



import { WoolCards } from "./WoolCards";


import ChampionFleece from "../../static/images/champion_fleece.jpeg";
import WhiteFleece from "../../static/images/white_fleece.jpeg";
import Fleece from "../../static/images/IMG_6580.jpg";
import Fleece1 from "../../static/images/IMG_1111.jpg"
import Fleece2 from "../../static/images/IMG_1113.jpg"
import Fleece3 from "../../static/images/IMG_1309.jpg"
import HeroMap from "./heromap";
// import AlaskaMap from "./alaskamap";


function Wool() {
    // const [reviews, setReviews] = useState([]);
    // const [form, setForm] = useState({product: "", review: "", customer: "", id: uuid() });
    // const [editing, setEditing] = useState(false);
    // const [number, setNumber] = useState(0);
    
    return (
        <>
            <div className="wool-hero">
            <h1>Tracking our finest fleeces!</h1>
                <h2>
                    Our Wool has been shipped all over the US to handspinners. It has gone to Alaska, Canada, Florida, Main, California, etc. The map below shows all the places our wool has been
                    shipped to.
                </h2>
               
               
                <HeroMap />
                </div>
            <div className="wool-main-wrapper">
                <div className="wool-top">
                <h1>
                    Our Wool is Purebred Columbia White Wool and Natural Colored Columbia
                    Rambouilet Cross.
                </h1>
                </div>
                <div className="wool-cardWrapper">
                  
                    <WoolCards
                        woolimgSrc={WhiteFleece}
                        // imgAlt="Card Image"
                        woolcardTitle="Average Staple Length"
                        woolcarddescription="Average Staple Lenghth is between 3-5 inches. A ewe's fleece weighs between 
                            8-9 pounds and a ram's fleece weighs between 10-12 pounds." 
                    />
                      <WoolCards
                        woolimgSrc={ChampionFleece}
                        // imgAlt="Card Image"
                        woolcardTitle="Many Colors"
                        woolcarddescription="Our wool can be purchased in Pure Black, Charcoal, Grey, Silver, White, Paint(Black and White Mix) " 
                    />
                    <WoolCards
                        woolimgSrc={Fleece}
                        // imgAlt="Card Image"
                        woolcardTitle="Micron"
                        woolcarddescription="Colored Fleece Micron: 26.8 and White Fleece Micron: 28" 
                    />
                      <WoolCards
                        woolimgSrc={Fleece1}
                        // imgAlt="Card Image"
                        // woolcardTitle="Micron"
                        // woolcarddescription="Colored Fleece Micron: 26.8 and White Fleece Micron: 28" 
                    />
                      <WoolCards
                        woolimgSrc={Fleece2}
                        // imgAlt="Card Image"
                        // woolcardTitle="Micron"
                        // woolcarddescription="Colored Fleece Micron: 26.8 and White Fleece Micron: 28" 
                    />
                      <WoolCards
                        woolimgSrc={Fleece3}
                        // imgAlt="Card Image"
                        // woolcardTitle="Micron"
                        // woolcarddescription="Colored Fleece Micron: 26.8 and White Fleece Micron: 28" 
                    />
                  
                </div>
           
            </div>

        </>
    );
}


export default Wool;
import React  from "react";
// import { v4 as uuid } from 'uuid';

import HeroSection from "./HeroSection";
// import { HomeCard } from "./HomeCards";


import { HomeCards } from "./HomeCards";



import LambChops from "../../static/images/lambchops.jpg";
import Wool from "../../static/images/champion_fleece.jpeg";
import  BreedingStock  from "../../static/images/IMG_4581.jpg";



// import { AiFillStar, AiOutlineStar } from "react-icons/ai";
// import "../../../styles/Home/HomeCards.scss";

// // import Form from "../../../utilities/Form/Form"
// // import ReviewList from "../../../utilities/Form/reviewList";

// import Wool from "../../static/images/champion_fleece.jpeg";
// import Hamners from "../../static/images/Hamners.jpg";
// import LambChops from "../../static/images/IMG_2908.jpg";
// import BreedingStock from "../../static/images/IMG_4581.jpg";
// import WoolPellets from "../../static/images/woolpellets.jpg";
// import WinnerCircle from "../../static/images/Ole.jpg";

function Home() {
    // const [reviews, setReviews] = useState([]);
    // const [form, setForm] = useState({product: "", review: "", customer: "", id: uuid() });
    // const [editing, setEditing] = useState(false);
    // const [number, setNumber] = useState(0);
    
    return (
        <>
        
            <HeroSection />
            <div className="main-wrapper">
                <div className="home-top">
                    <h1>Welcome to the Hamner Livestock Ranch.</h1> 
                    <h2>
                        Learn about us, our sheep, the quality meat, and the wool they produce.
                    </h2> 
                </div>
                <div className="home-cardWrapper">
                <HomeCards
                        homeimgSrc={Wool}
                        // imgAlt="Card Image"
                        homecardTitle="Our Wool"
                        homecarddescription="Tanya has broken out into the handspinner world with the Columbia fleeces. 
                        We sell black, charcoal, silver, grey, and white fleeces to handspinners all across the world. 
                        From California to Canada including Alaska to Florida. We show our fleeces at wool shows and have 
                        won many awards.  We have both Purebred Columbias and a Columbia Merino cross." 
            
                        homebuttonText="Learn More"
                        homecardLink="wool"
                        // homebuttonText="Learn More"
                        // homecardLink="meatforsale"
                    />
                    <HomeCards
                        homeimgSrc={LambChops}
                        // imgAlt="Card Image"
                        homecardTitle="Our Meat"
                        homecarddescription="Lamb has always been known to be small. The Hamner's have always thought of
                         them as just appetizers. Recently Tanya and Rex have experiemented and studied how to get a meal 
                         out of their lamb chops to where they didn't have to cook many sides to fill up on dinner. With this 
                         study not only do we have bigger lamb chops, it is also some of the best lamb tasting chops in the 
                         world. Our lambs are butcherd at 150-200 pounds to help bring a bigger chop to your plate." 
        
                        homebuttonText="Learn More"
                        homecardLink="meatforsale"
                    />
               
                     <HomeCards
                        homeimgSrc={BreedingStock}
                        // imgAlt="Card Image"
                        homecardTitle="Breeding Stock"
                        homecarddescription="We have quality rams, lambs, ewes that are available for fresh genes in your herd.
                         We have some amazing bloodlines in the Columbia breed. Our bloodlines go back to Woolstenhulme 409, 
                         Bredahl 9947, Osborne 184, Broken Dollar 132, Petersons, Robertsons, and Platts." 
                        homebuttonText="Learn More"
                        homecardLink="breedingstock"
        
                        // homebuttonText="Learn More"
                        // homecardLink="meatforsale"
                    />
                   
                </div>
           
            </div>

        </>
    );
}


export default Home;
import React from "react";

// import Meat from "../../static/images/ground.jpeg";

export const AboutCards = ({
  aboutimgSrc,
  // imgAlt,
  aboutcardTitle,
  aboutcarddescription,
  aboutbuttonText,
  aboutcardLink,
}) => {
  return (
    <div className="aboutcard-container">
      {aboutimgSrc &&  (<img src={aboutimgSrc}  className="aboutcard-img" />)}
       {aboutcardTitle && 
       <h1 className="aboutcard-title">{aboutcardTitle}</h1> }
       {aboutcarddescription && 
       <p className="aboutcard-description">
        {aboutcarddescription}</p>}

        {aboutbuttonText && aboutcardLink && (
       <a href = {aboutcardLink} className="aboutcard-btn">
        {aboutbuttonText}
       </a>
       )}
    </div>
  )
};
import React from "react";
// import { FaFontAwesome } from "react-icons/fa";
// import { FaFacebook } from "react-icons/fa";


// import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";



import Logo from "../static/images/logo2.png";

// import Facebook from "../static/images/facebooc-icon.png";
// import Instagram from "../static/images/instagram.png";
function Footer () {

    return (
        <section className="footer">
            <hr className="footer-seperator"/>
            <section className="footer-social-media">
                Find us on the Social Media &nbsp;&nbsp;&nbsp;&nbsp;
                <div className="footer-social-media-icon">
                <a href="https://www.facebook.com/hamnerlivestock/">
                <FontAwesomeIcon className="facebook" icon={faFacebook} />
                </a>
                <a href="https://www.instagram.com/hamner_livestock">
                <FontAwesomeIcon className="instagram" icon={faInstagram} />
                </a>
                </div>
               
            </section>
           
            <section className="footer-info">
                <section className=" footer-info-left">
                    Hamner Livestock Ranch
                    <img className="footer-logo" src={Logo} alt="Logo" />
                </section>
                <section className="footer-info-center">
                    Tanya Hamner: (307)679-8749<br></br>Gayle Hamner: (307)679-8752<br></br>
                    Rex Hamner: (307)679-5158

                </section>
                <section className="footer-info-right">
                  Email: hamnerlivestock@yahoo.com<br></br>
                 <p className="spam"> If you do not receive an email back, please call us.
                  Chances are your email went to the spam folder. </p>

                </section> 
            </section>
            <hr className="footer-seperator"/>
        </section>
    )
}

export default Footer;
// const Footer=()=> {
//     return (
//         <div className="footer">
//             <div className="footer section_padding">
//                 <div className="footer-info">
                    
//                     <h4>Contact: Tanya Hamner 307.679.8749 or Rex Hamner 307.679.5158</h4><br></br>
//                     <h4> Email: hamnerlivestock@yahoo.com</h4>
                    
//                 </div>
//             </div>
//         </div>
//     )
// }
// export default Footer;